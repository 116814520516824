import { AnalyticsBrowser } from "@segment/analytics-next";
import * as SentryRemix from "@sentry/remix";
import * as React from "react";

/**
 * AnalyticsBrowser class, but ensures `load` is only ever called once.
 */
class AnalyticsBrowserCached extends AnalyticsBrowser {
  private loadCalled: boolean;

  constructor() {
    super();
    this.loadCalled = false;
  }

  load: (typeof AnalyticsBrowser)["load"] = (...args) => {
    if (!this.loadCalled) {
      SentryRemix.addBreadcrumb({
        message: "Load Analytics first time!",
      });
      this.loadCalled = true;
      return super.load(...args);
    }

    return this;
  };
}

export const analytics = new AnalyticsBrowserCached();

export type AnalyticsContextProps = {
  anonymousId: string | null | undefined;
  analytics: AnalyticsBrowser;
};

export const defaultAnalyticsContextProps: AnalyticsContextProps = {
  // This is a placeholder value that will be replaced
  // by the provider with AnalyticsBrowser loaded with write key
  anonymousId: undefined,
  analytics,
};

export const AnalyticsContext = React.createContext<AnalyticsContextProps>(
  defaultAnalyticsContextProps,
);
