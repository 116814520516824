import * as React from "react";

export type DialogContextProps = {
  container: React.RefObject<HTMLElement | null>;
  content: React.RefObject<HTMLDivElement>;
};

export const defaultDialogContextProps = {
  container: { current: null },
  content: { current: null },
};

export const DialogContext = React.createContext<DialogContextProps>(
  defaultDialogContextProps,
);
